//
//
//
//
//
//
//
//
//
//

import defaultPage from '../mixins/page/default'
export default {
  name: 'Page',
  mixins: [defaultPage]
}
